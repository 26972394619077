import React from "react"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  background: {
    // fill: "#fff",
    // fill: "#0a355a",
    fill: "#0f4c81",
  },
  white: {
    // fill: "#0a355a", // "#fff",
    fill: "#fff",
  },
  transparent: {
    fill: "none",
  },

  cls3: {
    fill: "#fff",
    stroke: "#000",
    strokeMiterlimit: 10,
    strokeWidth: "0.25px",
  },
}))

export const FeatureIcon = ({ icon, width, height, className }) => {
  const classes = useStyles()

  switch (icon) {
    case "api": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 1001.9 1001.9"
          className={className}
        >
          <g id="dark_background" data-name="dark background">
            <rect
              className={classes.background}
              width="1001.9"
              height="1001.9"
              rx="69"
            />
          </g>
          <g id="API">
            <path
              className={classes.white}
              d="M860.78,502.25q-38.38-6.71-38.38-43.71V407.76q0-33.21-39.07-34.25V349.59q31.67,0,49.83,15t18.16,41.31v52.67q0,16.69,10.15,24.52T894,491.24v24.27q-21,0-31.84,7.65t-10.84,25.56v52.15q0,26.34-17.65,41T783.33,657V632.89q19.11-.35,29.09-8.27t10-25.47V548q0-19.62,9.47-29.94t28.91-13.77Z"
              transform="translate(1.01 0.48)"
            />
            <path
              class={classes.white}
              d="M410.75,603.7l-5.49-26h-1.34Q390.27,594.88,376.69,601T342.79,607q-27.17,0-42.57-14t-15.4-39.81q0-55.31,88.45-58l31-1V482.93q0-21.49-9.24-31.74T365.44,441q-22.81,0-51.64,14l-8.49-21.15a129.91,129.91,0,0,1,29.56-11.5,128.43,128.43,0,0,1,32.24-4.16q32.64,0,48.39,14.49t15.74,46.48V603.7Zm-62.47-19.49q25.83,0,40.57-14.16t14.74-39.64v-16.5l-27.65,1.17q-33,1.17-47.56,10.24T313.8,553.56q0,15,9.08,22.82T348.28,584.21Z"
              transform="translate(1.01 0.48)"
            />
            <path
              class={classes.white}
              d="M573.17,607a79.1,79.1,0,0,1-32.56-6.58,61.3,61.3,0,0,1-24.74-20.24h-2a245.28,245.28,0,0,1,2,30.31v75.13H488.22V421.13h22.49l3.83,25h1.33q10.65-15,24.82-21.65t32.48-6.67q36.33,0,56.06,24.83T649,512.25q0,45-20.07,69.88T573.17,607Zm-4-165.76q-28,0-40.48,15.49t-12.83,49.32v6.16q0,38.47,12.83,55.06t41.14,16.57q23.65,0,37.07-19.16t13.41-52.8q0-34.16-13.41-52.4T569.18,441.28Z"
              transform="translate(1.01 0.48)"
            />
            <path
              class={classes.white}
              d="M695,371.65q0-9.5,4.66-13.91a17.26,17.26,0,0,1,23.16.08q4.83,4.5,4.83,13.83t-4.83,13.91a16.93,16.93,0,0,1-23.16,0Q695,381,695,371.65Zm30,232.05H697.28V421.13h27.66Z"
              transform="translate(1.01 0.48)"
            />
            <path
              class={classes.white}
              d="M212.25,599.56q0,17.57,10.07,25.47t29.52,8.27v24.09q-32.7-.35-50.6-15t-17.9-41.14V549.13q0-17.89-10.85-25.56T141,515.92V491.65q22.37-.34,32.35-8.26t10-24.44V406.28q0-26.67,18.59-41.48T251.84,350v23.92q-39.59,1-39.59,34.25V459q0,37-38.38,43.71v2.07q38.38,6.7,38.38,43.71Z"
              transform="translate(1.01 0.48)"
            />
          </g>
        </svg>
      )
    }
    case "cli": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 1001.9 1001.9"
          className={className}
        >
          <g id="dark_background" data-name="dark background">
            <rect
              className={classes.background}
              width="1001.9"
              height="1001.9"
              rx="69"
            />
          </g>
          <g id="CLI">
            <path
              className={classes.white}
              d="M504,178.5H279.38a56.08,56.08,0,0,0-55.88,56.16L223.22,684a56.08,56.08,0,0,0,55.88,56.16H616.36A56.32,56.32,0,0,0,672.52,684V347Zm56.16,449.3H335.54V571.64H560.19Zm0-112.32H335.54V459.31H560.19ZM476,375.07V220.62L630.4,375.07Z"
              transform="translate(1.01 0.48)"
            />
            <circle
              className={classes.background}
              cx="718.26"
              cy="783.8"
              r="212.19"
            />
            <path
              className={classes.cls3}
              d="M600.17,715.27h37.45l-49.94-49.94-49.94,49.94H575.2a99.7,99.7,0,0,0,153.06,84.4L710,781.44a73.3,73.3,0,0,1-35,8.74A75,75,0,0,1,600.17,715.27Zm21.72-84.4,18.23,18.22A74.8,74.8,0,0,1,750,715.27H712.53l49.94,49.94,49.94-49.94H775a99.71,99.71,0,0,0-153.07-84.4Z"
              transform="translate(1.01 0.48)"
            />
          </g>
        </svg>
      )
    }
    case "code": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 1001.9 1001.9"
          className={className}
        >
          <g id="dark_background" data-name="dark background">
            <rect
              className={classes.background}
              width="1001.9"
              height="1001.9"
              rx="69"
            />
          </g>
          <g id="code">
            <path
              className={classes.white}
              d="M395.77,703,200.29,507.53,395.77,312.05l-59.49-59.49-255,255,255,255Zm203.77,0L795,507.53,599.54,312.05,659,252.56l255,255-255,255Z"
              transform="translate(1.01 0.48)"
            />
          </g>
        </svg>
      )
    }
    case "computer": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 1001.9 1001.9"
          className={className}
        >
          <g id="dark_background" data-name="dark background">
            <rect
              className={classes.background}
              width="1001.9"
              height="1001.9"
              rx="69"
            />
          </g>
          <g id="computer">
            <path
              className={classes.white}
              d="M748.39,663.69c34,0,61.49-27.81,61.49-61.8l.31-309A62,62,0,0,0,748.39,231H253.93a62,62,0,0,0-61.81,61.81v309a62,62,0,0,0,61.81,61.8H130.31V725.5H872V663.69ZM253.93,292.85H748.39v309H253.93Z"
              transform="translate(1.01 0.48)"
            />
          </g>
        </svg>
      )
    }

    case "unlimited": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 1001.9 1001.9"
          className={className}
        >
          <g id="dark_background" data-name="dark background">
            <rect
              className={classes.background}
              width="1001.9"
              height="1001.9"
              rx="69"
            />
          </g>
          <g id="schlaufe">
            <path
              className={classes.white}
              d="M739.84,287c-51.8,0-100.73,20.15-135.62,55L502.41,432.3l-54.68,48.2h.36l-96.76,86A121.06,121.06,0,0,1,265,602.09c-67.27,0-122-54.32-122-121.59s54.68-121.59,122-121.59c32.74,0,63.31,12.59,87.78,37l40.65,36,54.31-48.21L402.41,343.8A193.22,193.22,0,0,0,265,287C157.79,287,70.74,374,70.74,480.5S157.79,674,265,674c51.8,0,100.73-20.15,135.62-55l101.8-89.94.36.36,54.32-48.92h-.36l96.77-86a121,121,0,0,1,86.34-35.61c67.27,0,121.95,54.32,121.95,121.59S807.11,602.09,739.84,602.09c-32.38,0-63.32-12.59-87.78-37.05l-41-36.34-54.32,48.21,45.69,40.29a193.76,193.76,0,0,0,137.42,56.48c107.2,0,194.25-86.7,194.25-193.54S847,287,739.84,287Z"
              transform="translate(1.01 0.48)"
            />
          </g>
        </svg>
      )
    }
    case "glossary": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 1001.9 1001.9"
          className={className}
        >
          <g id="dark_background" data-name="dark background">
            <rect
              className={classes.background}
              width="1001.9"
              height="1001.9"
              rx="69"
            />
          </g>
          <g id="glossary">
            <path
              className={classes.white}
              d="M687.82,495.9"
              transform="translate(1.01 0.48)"
            />
            <path
              className={classes.white}
              d="M761.05,272.53c-32.16-10.14-67.5-14.48-101.4-14.48-56.5,0-117.34,11.59-159.35,43.46-42-31.87-102.85-43.46-159.35-43.46S223.61,269.64,181.6,301.51V726c0,7.24,7.24,14.48,14.49,14.48,2.89,0,4.34-1.45,7.24-1.45,39.11-18.83,95.61-31.87,137.62-31.87,56.5,0,117.34,11.59,159.35,43.46,39.11-24.62,110.1-43.46,159.35-43.46,47.81,0,97.06,8.7,137.62,30.43,2.9,1.44,4.35,1.44,7.24,1.44,7.25,0,14.49-7.24,14.49-14.48v-423C801.62,288.47,782.78,279.78,761.05,272.53Zm0,391.14c-31.87-10.15-66.63-14.49-101.4-14.49-49.25,0-120.24,18.83-159.35,43.46V359.45C539.41,334.83,610.4,316,659.65,316c34.77,0,69.53,4.35,101.4,14.49Z"
              transform="translate(1.01 0.48)"
            />
            <path
              className={classes.white}
              d="M659.65,431.88a338.47,338.47,0,0,1,72.43,7.54v-44a390.83,390.83,0,0,0-72.43-7c-49.25,0-93.87,8.41-130.38,24.05v48.1C562,442,607.5,431.88,659.65,431.88ZM529.27,489.54v48.09C562,519.09,607.5,509,659.65,509a339.12,339.12,0,0,1,72.43,7.53v-44a390.83,390.83,0,0,0-72.43-7C610.4,465.49,565.78,474.18,529.27,489.54Zm130.38,53.31c-49.25,0-93.87,8.4-130.38,24V615c32.74-18.54,78.23-28.68,130.38-28.68a339.12,339.12,0,0,1,72.43,7.53v-44A368.26,368.26,0,0,0,659.65,542.85Z"
              transform="translate(1.01 0.48)"
            />
          </g>
        </svg>
      )
    }
    case "edit": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 1001.9 1001.9"
          className={className}
        >
          <g id="dark_background" data-name="dark background">
            <rect
              className={classes.background}
              width="1001.9"
              height="1001.9"
              rx="69"
            />
          </g>
          <g id="Ebene_10" data-name="Ebene 10">
            <path
              className={classes.white}
              d="M657.6,508.36A126.19,126.19,0,1,0,783.79,634.55,126.23,126.23,0,0,0,657.6,508.36ZM632.36,697.64l-63.09-63.09,17.79-17.8,45.3,45.18,95.78-95.78,17.79,17.92Z"
              transform="translate(1.01 0.48)"
            />
            <path
              className={classes.white}
              d="M548.45,619.91l-50.88-130H333.74l-50.32,130H235.33L396.92,209.5h40L597.65,619.91Zm-65.7-172.77L435.22,320.49q-9.23-24-19-59a529.84,529.84,0,0,1-17.61,59L350.51,447.14Z"
              transform="translate(1.01 0.48)"
            />
          </g>
        </svg>
      )
    }

    case "variable": {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 1001.9 1001.9"
          className={className}
        >
          <g id="dark_background" data-name="dark background">
            <rect
              className={classes.background}
              width="1001.9"
              height="1001.9"
              rx="69"
            />
          </g>
          <g id="interpolation">
            <path
              className={classes.white}
              d="M744.23,456q-32.31-5.64-32.31-36.79V376.47q0-28-32.88-28.83V327.51q26.65,0,41.94,12.6t15.28,34.77v44.33q0,14.06,8.55,20.64t27.38,6.88v20.43q-17.69,0-26.8,6.44t-9.13,21.52V539q0,22.17-14.85,34.55T679,586.24V566q16.08-.29,24.48-7t8.4-21.44v-43q0-16.52,8-25.21t24.34-11.59Z"
              transform="translate(1.01 0.48)"
            />
            <path
              className={classes.white}
              d="M198.37,537.91q0,14.77,8.48,21.44t24.84,6.95v20.28q-27.52-.29-42.59-12.6T174,539.35V495.46q0-15.06-9.12-21.51T138.4,467.5V447.08q18.82-.3,27.23-7t8.4-20.57V375.22q0-22.44,15.65-34.91t42-12.46V348q-33.31.87-33.32,28.83v42.73q0,31.15-32.3,36.8v1.74q32.3,5.64,32.3,36.79Z"
              transform="translate(1.01 0.48)"
            />
            <path
              className={classes.white}
              d="M308.61,533.93,252.13,385.1H276.3l32,88.26q10.88,31,12.77,40.2h1.08q1.5-7.2,9.44-29.81t35.65-98.65h24.16L335,533.93Z"
              transform="translate(1.01 0.48)"
            />
            <path
              className={classes.white}
              d="M506.86,533.93l-4.48-21.19H501.3q-11.15,14-22.21,18.94t-27.63,5q-22.12,0-34.69-11.4t-12.56-32.46q0-45.07,72.1-47.25l25.26-.82v-9.23q0-17.52-7.54-25.87t-24.1-8.35q-18.6,0-42.1,11.41l-6.92-17.25a106.42,106.42,0,0,1,24.1-9.37,104.6,104.6,0,0,1,26.28-3.39q26.61,0,39.44,11.81t12.83,37.89V533.93ZM455.94,518q21,0,33.07-11.54t12-32.32V460.74l-22.54.95q-26.88.94-38.76,8.35t-11.89,23q0,12.22,7.41,18.61T455.94,518Z"
              transform="translate(1.01 0.48)"
            />
            <path
              className={classes.white}
              d="M637.9,382.39A87.93,87.93,0,0,1,655.69,384l-3.13,20.91a76.64,76.64,0,0,0-16.29-2q-18.06,0-30.89,14.67t-12.84,36.52v79.85H570V385.1h18.61l2.58,27.57h1.08q8.28-14.54,20-22.41A45,45,0,0,1,637.9,382.39Z"
              transform="translate(1.01 0.48)"
            />
            <path
              className={classes.white}
              d="M743.09,488.93A126.19,126.19,0,1,0,869.28,615.12,126.23,126.23,0,0,0,743.09,488.93ZM717.85,678.22l-63.09-63.1,17.79-17.79,45.3,45.18,95.78-95.78,17.79,17.92Z"
              transform="translate(1.01 0.48)"
            />
          </g>
        </svg>
      )
    }

    default: {
      return null
    }
  }
}

export default FeatureIcon
