import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import FeatureIcon from "./FeatureIcon"

import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: "40px",
  },
  mainTitle: {
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.54)",
  },
  featureTitle: {
    textAlign: "center",
    fontSize: "1.4rem",
    margin: "1rem 0",
    color: "rgba(0, 0, 0, 0.54)",
  },
  feature: {
    textAlign: "center",
    margin: "0 20px",
    paddingBottom: "40px",
  },

  featureIcon: {
    display: "block",
    margin: "auto",
  },
}))

const Features = () => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <Grid container>
        <Grid item md="4">
          <FeatureIcon
            icon="computer"
            width={60}
            height={60}
            className={classes.featureIcon}
          />
          <Typography variant="h3" className={classes.featureTitle}>
            Online Translator
          </Typography>

          <Typography variant="body1" className={classes.feature}>
            Simply copy/paste your files into the web translator
          </Typography>
        </Grid>
        <Grid item md="4">
          <FeatureIcon
            icon="cli"
            width={60}
            height={60}
            className={classes.featureIcon}
          />
          <Typography variant="h3" className={classes.featureTitle}>
            Continuous Localization
          </Typography>
          <Typography variant="body1" className={classes.feature}>
            Integrate instant translations in your development workflow
          </Typography>
        </Grid>
        <Grid item md="4">
          <FeatureIcon
            icon="api"
            width={60}
            height={60}
            className={classes.featureIcon}
          />
          <Typography variant="h3" className={classes.featureTitle}>
            Custom Use
          </Typography>
          <Typography variant="body1" className={classes.feature}>
            Use the Simpleen API to solve individual challenges
          </Typography>
        </Grid>
        {/* 2nd row */}
        <Grid item md="4">
          <FeatureIcon
            icon="variable"
            width={60}
            height={60}
            className={classes.featureIcon}
          />
          <Typography variant="h3" className={classes.featureTitle}>
            Advanced Variable Handling
          </Typography>
          <Typography variant="body1" className={classes.feature}>
            Keep variables/interpolations and get better translation results -
            even ICU Messages
            {/*
            Using variables/interpolations, even ICU
            Messages, to provide suitable translation results.
           */}
          </Typography>
        </Grid>
        <Grid item md="4">
          <FeatureIcon
            width={60}
            height={60}
            icon="glossary"
            className={classes.featureIcon}
          />
          <Typography variant="h3" className={classes.featureTitle}>
            Customizable Glossaries
          </Typography>
          <Typography variant="body1" className={classes.feature}>
            Create personal glossaries to fix translation results
          </Typography>
        </Grid>
        <Grid item md="4">
          <FeatureIcon
            width={60}
            height={60}
            icon="edit"
            className={classes.featureIcon}
          />
          <Typography variant="h3" className={classes.featureTitle}>
            Edit Translations
          </Typography>
          <Typography variant="body1" className={classes.feature}>
            Adapt translations locally or online and receive current
            translations anytime
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Features
