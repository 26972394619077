import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: "40px",
  },
  mainTitle: {
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.54)",
  },
  questionTitle: {
    fontSize: "1.4rem",
    marginTop: "1.5rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  question: {},
  leftColumn: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "40px",
    },
  },
  rightColumn: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "40px",
    },
  },
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
}))

const FAQ = () => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <Typography variant="h2" className={classes.mainTitle}>
        Common Questions
      </Typography>
      <Grid container>
        <Grid item md="6" className={classes.leftColumn}>
          <Typography variant="h3" className={classes.questionTitle}>
            Which plan should I choose?
          </Typography>
          <Typography variant="body1" className={classes.question}>
            Each plan has a monthly quota of text segments that can be
            translated/localized. Choose a plan that reflects your workload of
            target segments. For example, if your JSON file consists of 800
            keys/strings and you want to translate it into 3 languages (2400
            text segments), you can sign up for the Small Plan. In case you are
            unsure what plan you need, choose the smaller one and upgrade when
            you need it.
          </Typography>
          <Typography variant="h3" className={classes.questionTitle}>
            Can I switch to a different plan in case my usage changes?{" "}
          </Typography>
          <Typography variant="body1" className={classes.question}>
            Yes, you can switch plans anytime. You will pay the original plan
            pro-rata to the day you change your plan and from then your new
            billing cycle on the new plan starts.
          </Typography>
          <Typography variant="h3" className={classes.questionTitle}>
            What kind of products can I localize with Simpleen?
          </Typography>{" "}
          <Typography variant="body1" className={classes.question}>
            You can localize software, games, mobile apps, web applications and
            documentations. Localize with our Online Translator, use the CLI for
            continuous localization or the API to customize JSON localization.
          </Typography>
        </Grid>
        <Grid item md="6" className={classes.rightColumn}>
          {" "}
          <Typography variant="h3" className={classes.questionTitle}>
            What happens if I cancel a paid plan/subscription?{" "}
          </Typography>
          <Typography variant="body1" className={classes.question}>
            You can cancel your plan anytime. The current subscription will
            continue to the end of your current billing cycle, but will not
            renew.
          </Typography>
          <Typography variant="h3" className={classes.questionTitle}>
            {" "}
            What payment methods can I use?
          </Typography>
          <Typography variant="body1" className={classes.question}>
            Our checkout is powered by Paddle. They offer payment with major
            credit/debit cards and PayPal.
          </Typography>
          <Typography variant="h3" className={classes.questionTitle}>
            What if I don't like Simpleen, is there a refund policy?
          </Typography>{" "}
          <Typography variant="body1" className={classes.question}>
            If you encounter any problems or our tool is not suitable for your
            task, please{" "}
            <a href="mailto:info@simpleen.io" className={classes.a}>
              let us know
            </a>
            . You can refund within 7 days of the original purchase.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default FAQ
