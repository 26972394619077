import React from "react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Grid from "@material-ui/core/Grid"
import StarIcon from "@material-ui/icons/StarBorder"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout/layout"
import Faq from "../components/pricing/faq"
import Features from "../components/pricing/features"

const useStyles = makeStyles(theme => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  a: {
    textDecoration: "none",
    color: "#0f4c81",
  },
  inclTax: {
    textAlign: "center",
    marginTop: "-20px",
    marginBottom: "20px",
  },
  signupFreeButton: {
    marginTop: "10px",
  },
}))

const formatNumber = num => {
  const language =
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.language
      ? window.navigator.language
      : "en-US"
  return new Intl.NumberFormat(language, {}).format(num)
}

const tiers = [
  {
    title: "Starter",
    price: "15",
    description: [
      <>
        <b>{formatNumber(1000)}</b> Text Segments
        <br />
      </>,
      <>
        <b>Unlimited</b> Languages
        <br />
      </>,
      <>
        <b>All</b> Formats & Libraries
        <br />
      </>,
      <>
        <b>Email</b> Support
      </>,
    ],
    buttonText: "Get started",
    buttonVariant: "outlined",
  },
  {
    title: "Small",
    price: "35",
    description: [
      <>
        <b>{formatNumber(3000)}</b> Text Segments
      </>,
      <>
        <b>Unlimited</b> Languages
        <br />
      </>,
      <>
        <b>All</b> Formats & Libraries
        <br />
      </>,
      <>
        <b>Email</b> Support
      </>,
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    title: "Medium",
    price: "75",
    description: [
      <>
        <b>{formatNumber(10000)}</b> Text Segments
      </>,
      <>
        <b>Unlimited</b> Languages
        <br />
      </>,
      <>
        <b>All</b> Formats & Libraries
        <br />
      </>,
      <>
        <b>Email</b> Support
      </>,
    ],
    buttonText: "get started",
    buttonVariant: "outlined",
  },
  {
    title: "Large",
    price: "145",
    description: [
      <>
        <b>{formatNumber(25000)}</b> Text Segments
      </>,
      <>
        <b>Unlimited</b> Languages
        <br />
      </>,
      <>
        <b>All</b> Formats & Libraries
        <br />
      </>,
      <>
        <b>Email</b> Support
      </>,
    ],
    buttonText: "Get started",
    buttonVariant: "outlined",
  },
]

const freePlan = {
  title: "Free Plan",
  price: "0",
  description: [
    <>
      <b>{formatNumber(100)}</b> Text Segments,{" "}
    </>,
    <>
      <b>{formatNumber(30)}</b> No Code Calls{" "}
    </>,
  ],
  buttonText: "Get started",
  buttonVariant: "outlined",
}

const noCodePlan = {
  title: "NoCode Plan",
  price: "10",
  description: [
    <>
      <b>{formatNumber(300)}</b> No Code Calls
      <br />
    </>,
    <>Translate with Integromat or Zapier</>,
  ],
}

export default function Pricing() {
  const classes = useStyles()

  return (
    <Layout>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Simple Monthly Plan
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          Choose a plan that fits your project needs.
          <br /> Change or cancel anytime.
        </Typography>
        {/* Free Plan */}
        <br />
        <br />
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        >
          Start with our Free Plan:
          <br />
        </Typography>
        <Typography variant="body1" align="center" component="p">
          {freePlan.description.map(line => (
            <span key={line}>{line}</span>
          ))}
          <br />
          <Button
            className={classes.signupFreeButton}
            variant="contained"
            color="primary"
            href="/signup"
          >
            Get started
          </Button>
        </Typography>
      </Container>
      {/* End hero unit */}

      <Container maxWidth="lg" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={6}
              md={tier.title === "Free" ? 6 : 3}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={tier.title === "Small" ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /month
                    </Typography>
                  </div>
                  <Typography variant="body1" className={classes.inclTax}>
                    incl. VAT
                  </Typography>
                  <ul>
                    {tier.description.map(line => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color="primary"
                    href="/signup"
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}

          {/* No Code Plan*/}
          <Container
            maxWidth="sm"
            component="main"
            className={classes.heroContent}
          >
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              {noCodePlan.title}
              <br />
            </Typography>
            <div className={classes.cardPricing}>
              <Typography component="h2" variant="h3" color="textPrimary">
                ${noCodePlan.price}
              </Typography>
              <Typography variant="h6" color="textSecondary">
                /month
              </Typography>
            </div>
            <Typography variant="body1" align="center" component="p">
              {noCodePlan.description.map(line => (
                <span key={line}>{line}</span>
              ))}
              <br />
              <Button
                className={classes.signupFreeButton}
                variant="outlined"
                color="primary"
                href="/signup"
              >
                Get started
              </Button>
            </Typography>
          </Container>

          {/* Free Plan 
          <Grid item key={freePlan.title} xs={12} sm={6} md={6}>
            <Card>
              <CardHeader
                title={freePlan.title}
                subheader={freePlan.subheader}
                titleTypographyProps={{ align: "center" }}
                subheaderTypographyProps={{ align: "center" }}
                action={freePlan.title === "Medium" ? <StarIcon /> : null}
                className={classes.cardHeader}
              />
              <CardContent>
                <div className={classes.cardPricing}>
                  <Typography component="h2" variant="h3" color="textPrimary">
                    ${freePlan.price}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    /month
                  </Typography>
                </div>
                <Typography variant="body1" className={classes.inclTax}>
                  incl. VAT
                </Typography>
                <ul>
                  {freePlan.description.map(line => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={freePlan.buttonVariant}
                  color="primary"
                  href="/signup"
                >
                  {freePlan.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
                    */}

          {/*
          <Grid item md={3} />
          <Grid item md={3} />
          <Grid item md={6}>
            <Card>
              <CardHeader
                title="We offer the same features for all plans:"
                // subheader={tier.subheader}
                titleTypographyProps={{ align: "center" }}
                subheaderTypographyProps={{ align: "center" }}
                className={classes.cardHeader}
              />
              <CardContent>
                <p style={{ textAlign: "center" }}>
                  Online Translator, CLI & API <br />
                  Interpolation/Variable handling <br />
                  Personal glossaries <br />
                  Use all available formats <br />
                  ICU Message translations
                </p>
                <p style={{ textAlign: "center" }}>
                  Need more? Contact us to{" "}
                  <a href="mailto:info@simpleen.io" className={classes.a}>
                    get a Custom Plan.
                  </a>
                </p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}></Grid>
          */}
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      <Features />
      <br />
      <br />
      <Faq />
      <br />
    </Layout>
  )
}
